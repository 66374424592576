import {handleImageLoad} from './components/handleImageLoad';
import {observeScroll} from './components/observe-scroll';
import {pauseVideos} from './components/brightcove-videos';

import {viewportCheck} from './components/viewport-check';
import {cursorFollow} from './components/cursor-follow';
import {projectsGrid} from './components/projectsGrid';
import {initSliders} from './components/splidejs';
import {reportsPage} from './components/reports-page';
import {LoadReports} from './components/reports-page';
import {GetParameters} from './components/reports-page';
import {reportDownloadFormHandler} from './components/reportDownload';
import {mediaBlocks2x} from './components/media-blocks-2x';
import {newsletterForm} from './components/newsletter-form';
import {mediaCarousel} from './components/media-carousel';
import {enableVideoAutoplay} from './components/video-autoplay';
import {scrollTrigger} from './components/scroller';

import {handleSubscribe} from './components/subscribe-component';

// ! Dev script - Shows the grid
// document.addEventListener('keydown', function(event) {
// 	if (event.shiftKey && event.key === 'G') {
// 		var element = document.getElementsByClassName('design-grid')[0];
// 		element.classList.toggle('show');
// 	}
// });

document.addEventListener("DOMContentLoaded", function () {
	// The DOM has loaded (but other stuff hasnt yet neccessarily)
	if(!$('body').hasClass('contentPage')) {
		observeScroll();
	}
	enableVideoAutoplay();
	handleSubscribe();
});

window.addEventListener("load", (event) => {
	// alert("Window Loaded");
	// console.log("Window Loaded");


	cursorFollow();


	// Homepage conveyor
	const sliderSpeed = 0.4;
	$.when(initSliders(sliderSpeed)).then(function() {
		$('div.project-slides > div').addClass("ready");
	});


	// Projects Grid filter
	projectsGrid();


	// Reports page 
	reportsPage();
	LoadReports(GetParameters(), true);
	reportDownloadFormHandler();
	
	newsletterForm();
	mediaCarousel();
	
	scrollTrigger();
	
	// Check when the footer is loaded
	const body = document.querySelector('body');
	const footer = document.querySelector('body > .main-footer');
	viewportCheck(footer, body);
});

function VideoTransform() {
	// Check if video is in middle
	// calculate percentage value of steps between 100% and video size 
	//if scroll up, increase size by percentage - 
	// if scroll down decrese percetange until > 0

	var lastScrollTop = 0;
	const videoEle = 'main div.umb-block-list div.single-:first-child';
	if ($(videoEle).length < 1) {
		return;
	} 

	$(window).on('scroll', function () {
		//var videoEle = document.querySelector('main div.umb-block-list div.single-media');
		if (isInViewport(videoEle)) {
			var ele = document.querySelector(videoEle);

			//console.log('Just hit mid');
			// Update css 
			// if($(ele).css('max-width') && $(ele).css('max-width') === '100%') {
			// 	$(ele).css('width', $(ele).css('max-width')).css('max-width', '100%');
			// }

			//var maxwidth = document.querySelector('main div.umb-block-list').offsetWidth;

			var st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > lastScrollTop) {
				//debugger;
				//console.log('Increase size');
				var newwidth = $(ele).width() + 2;
				$(ele).css('max-width', newwidth + 'px;');
				//return 'up';
			} else if (st < lastScrollTop) {
				//console.log('Decrease size');
				var newwidth = $(ele).width() - 2;
				$(ele).css('max-width', newwidth + 'px;');
				//return 'down';
			} // else was horizontal scroll
			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

		} else {
			//console.log('NOT in VP');
		}

	});
	//scrollDetect();
}

function scrollDetect() {
	var lastScrollTop = 0;

	// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
	window.addEventListener("scroll", function () { // or window.addEventListener("scroll"....
		var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
		if (st > lastScrollTop) {
			//console.log('Scroll Up');
			return 'up';
		} else if (st < lastScrollTop) {
			//console.log('Scroll Down');
			//return 'down';
		} // else was horizontal scroll
		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}, false);
}

function isInViewport(element) {
		var videoEle = document.querySelector(element);
		const rect = videoEle.getBoundingClientRect();
	return (
		rect.top <= window.innerHeight &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}