import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Video } from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import {handleImageLoad} from './handleImageLoad';
import {checkVideosInitiated} from './brightcove-videos';

export function projectsGrid() {
	const projSliders = document.getElementsByClassName('splide projects-splide');

	var ele = 'div.projects-grid div.grid-filter-options input[type="radio"]';	

	$(ele).on('change', e => {
		$('div.project-slides > div').removeClass("ready");
		$('div.project-slides .splide').remove();
		// var selectedCategories = [];
		// var checkboxes = $('div.projects-grid div.grid-filter-options input[type="radio"]:checked');
		// $('div.projects-grid div.grid-filter-options input[type="checkbox"]:radio').each(function (c) {
		// 	selectedCategories.push($(checkboxes[c]).attr('data-val'));
		// });

		var postData = { categories: $('div.projects-grid div.grid-filter-options input[type="radio"]:checked').attr('data-val') + "", rows: 3 };
		//console.log("projectsGrid categories: ", selectedCategories);

		var _url = '/umbraco/surface/Projects/LoadProjectSlider';
		$.ajax({
			type: "get",
			url: _url,
			data: postData,
			success: function (result) {
				const sliderSpeed = 0.4;
				$.when($('div.project-slides').append(result))
				.then(function () { InitProjectSlider(sliderSpeed); })
					.then(function () {

						//console.log("Moment 1");
						var players = videojs.getAllPlayers();
						var keys = Object.keys(players);
						for (let i = 0; i < keys.length; i++) {
							
							//console.log("Moment 2");
							players[keys[i]].play();
						}
					})
					.then(function () {
						
						//console.log("Moment 3");

						$('div.project-slides > div').addClass("ready");
						const projectSlides = document.querySelector(".projects-grid");
						checkVideosInitiated(projectSlides);
					})
					.then(function () {
						
						//console.log("Moment 3a");

						const projectSlides = document.querySelector(".projects-grid");
						handleImageLoad(projectSlides);
					});
			},
		});
	});

	function InitProjectSlider(speed) {
		
		//console.log("Moment 4");

		for (var i = 0; i < projSliders.length; i++) {
			var splide = new Splide(projSliders[i], {
				type: 'loop',
				perPage: 4,
				padding: '5rem',
				focus: 'center',
				direction: ($(projSliders[i]).attr('dir') === 'rtl' ? 'rtl' : 'ltr'),
				lazyLoad: 'nearby',
				drag: 'free',
				autoWidth: false,
				autoScroll: {
					speed: speed,
				},
				autoHeight: true,
				video: {
					autoplay: true,
					loop: true,
					mute: true,
				},
				breakpoints: {
					1000: {
						perPage: 2,
					},
					640: {
						perPage: 1,
						padding: '2rem',
					},
					480: {
						perPage: 1,
						padding: '1rem',
					},
				},
			});

			splide.mount({ Video, AutoScroll });

			splide.on( 'visible', function (event, target) {
				//console.log("SPLIDE visible");
				// console.log("event: ", event);
				// console.log("target: ", target);
				// do something
			});

			splide.on( 'ready', function () {
				//console.log("SPLIDE READY");
				// do something
			});

			splide.on( 'refresh', function () {
				//console.log("SPLIDE refresh");
				// do something
			});

			splide.on( 'refresh', function () {
				//console.log("SPLIDE refresh");
				// do something
			});


			


		}
	}
}