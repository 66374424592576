import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export function scrollTrigger() {
    const triggerSelector = "body.contentPage .single-media";

    // * Before we begin, lets check if the target exists
    const pageTriggerExists = !!document.querySelector(triggerSelector);
    console.log("pageTriggerExists: ", pageTriggerExists);
    if (!pageTriggerExists) {
        // * Break clause *
        // No trigger on the page. Exit function.
        console.log("No trigger on the page. Exit function.");

        return false;
    } else {
        console.log("Continue with function");
    }

    console.log("Animation logic kicks in from here...");


    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: triggerSelector,
            scrub: true,
            pin: true,
            start: "50% 50%",
            end: "+=200%"
        }
    })

    .from("body.contentPage .single-media > video-js", {
        scale: .5,
        ease: "none"
    })

    .to(".single-media > video-js", {
        scale: 1,
        ease: "none"
    })
}