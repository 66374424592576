import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export function scrollTrigger() {

    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: "body.contentPage .single-media",
            scrub: true,
            pin: true,
            start: "50% 50%",
            end: "+=200%"
        }
    })

    .from("body.contentPage .single-media > video-js", {
        scale: .5,
        ease: "none"
    })

    .to(".single-media > video-js", {
        scale: 1,
        ease: "none"
    })
}