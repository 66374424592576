export function handleSubscribe() {
	const subscribeComponent = document.querySelector('.subscribe-component');

	function switchView(currentViewClass, nextViewClass) {
		subscribeComponent.classList.remove(currentViewClass);
		subscribeComponent.classList.add(nextViewClass);
	}

	function validateCurrentView(viewClass) {
		let valid = true;
		const thisContainer = document.querySelector('.view.' + viewClass);
		const valMsgArea = thisContainer.querySelector(`.${viewClass} .val-msg`);
		valMsgArea.innerHTML = '';

		thisContainer.querySelectorAll(`.${viewClass} .ds-required`).forEach(field => {
			if ((field.type === 'checkbox' && !field.checked)) {
				valid = false;
				valMsgArea.innerHTML += `<p class="studio-help studio-is-danger">"${field.getAttribute('data-error')}"</p>`;
			}
			else if (!field.value.trim()) {
				valid = false;
				valMsgArea.innerHTML += `<p class="studio-help studio-is-danger">"${field.placeholder}" is required.</p>`;
			}
		});
		return valid;
	}

	function nextStep(currentViewClass, nextViewClass) {
		if (validateCurrentView(currentViewClass)) {
			switchView(currentViewClass, nextViewClass);
		}
	}

	function submitForm() {
		if (!validateCurrentView('details-view')) return;

		const formData = {
			"FirstName": document.getElementById('firstName').value,
			"LastName": document.getElementById('lastName').value,
			"$email": document.getElementById('email').value,
			"WantToWorkWithUs": document.getElementById('workWithUs').checked,
			"Industry": document.getElementById('industry').value,
			"Position": document.getElementById('position').value,
			"Seniority": document.getElementById('seniority').value
		};

		fetch('/Umbraco/Api/SubscriptionApi/SaveNewsletterSubscriptionForm', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(formData)
		})
			.then(response => response.json())
			.then(data => {
				// ! Debugging - Check if the response is JSON
				const valMsgArea = document.querySelector('.view.details-view .val-msg'); // Select the validation message area
				let debugString = "";

				if (typeof data === 'object') {
					// * Hide the debug string from users, but leave it in for us to see.
					debugString += '<div style="display: none">';


					// * Display the JSON response
					debugString += '<p class="response studio-help studio-is-success"><strong>Response:</strong><br /><pre>';
					debugString += '<b>(2): </b>';
					debugString += JSON.stringify(data);
					debugString += '</pre ></p>';

					debugString += '<p class="response studio-help studio-is-success"><strong>Response:</strong><br /><pre>';
					debugString += '<b>(4): Success?</b> - ';
					debugString += data.success;
					debugString += '</pre ></p>';


					// * Hide the debug string from users, this ENDs the wrapper.
					debugString += '</div>';

					// valMsgArea.innerHTML = debugString;

					// ! Hack for testing
					// data.success = true;

					console.log("data.success: ", data.success);
					if (data.success) {
						valMsgArea.innerHTML = `<p class="response studio-help studio-is-danger"><strong>Submission successful: </strong><br /><pre>${debugString}</pre></p>`;
						switchView('details-view', 'thanks-view'); // Switch to thank-you view on success
					} else {
						valMsgArea.innerHTML = `<p class="studio-help studio-is-danger">Submission failed: ${data.message}</p>${debugString}`;
					}
				} else {
					// Display the plain text response
					// console.log("Response was HTML 👎");
					valMsgArea.innerHTML = `<p class="studio-help studio-is-danger">Invalid response format received.</p> ${debugString}`;
					// valMsgArea.innerHTML = `<p class="studio-help studio-is-danger">Invalid response format received. Please try again</p>`;
				}

			})
			.catch(error => {
				document.querySelector('.details-view .val-msg').innerHTML = '<p class="studio-help studio-is-danger">Submission failed. Please try again.</p> ' + debugString; // Show error message
			});
	}

	document.querySelector('#nextBtn').addEventListener('click', () => nextStep('contact-view', 'details-view'));
	document.querySelector('#submitBtn').addEventListener('click', submitForm);
}
